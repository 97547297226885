import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HomeComponent, SafeHtmlPipe } from './home.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TabPlaceorderComponent } from './tab-placeorder/tab-placeorder.component';
import { TabMyordersComponent } from './tab-myorders/tab-myorders.component';
import { TabMyaccountComponent } from './tab-myaccount/tab-myaccount.component';
import { TabMyratesComponent } from './tab-myrates/tab-myrates.component';
import { TabDepotOriginsComponent } from './tab-depotorigins/tab-depotorigins.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        PdfViewerModule
    ],
    declarations: [
        SafeHtmlPipe,
        HomeComponent,
        TabPlaceorderComponent,
        TabMyordersComponent,
        TabMyaccountComponent,
        TabMyratesComponent,
        TabDepotOriginsComponent
    ],
    exports: [HomeComponent],
    providers: []
})
export class HomeModule { }
