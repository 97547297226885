import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class DataSourceService {
    private _provinces$ = new BehaviorSubject<Province[]>([]);
    private _disticts$ = new BehaviorSubject<District[]>([]);
    private _cities$ = new BehaviorSubject<City[]>([]);

    private _selectedProvince$ = new BehaviorSubject<string>(null);
    private _filteredDistricts$ = new BehaviorSubject<District[]>([]);

    private _selectedCity$ = new BehaviorSubject<string>(null);
    private _filteredCities$ = new BehaviorSubject<City[]>([]);

    constructor(private http: HttpClient) {
        //this.getProvinces().subscribe(data => {
        //    console.log(data);
        //    this._provinces$.next(data);
        //});

        //this.getDistricts().subscribe(data => {
        //    console.log(data);
        //    this._disticts$.next(data);
        //});

        this.getCities().subscribe(data => {
            //console.log(data);
            this._cities$.next(data);
        });

        // on selected province changed => load districts belong to province
        this._selectedProvince$.subscribe(provinceId => {
            //console.log(provinceId);
            this.filterDistricts$(provinceId);
        });

        // on selected district changed => load cities belong to district
        this._selectedCity$.subscribe(districtId => {
            //console.log(districtId);
            this.filterCities$(districtId);
        });
    }

    getProvinces(): Observable<Array<Province>> {
        return this.http.get<Array<Province>>("assets/resources/provinces.json");
    }

    getDistricts(): Observable<Array<District>> {
        return this.http.get<Array<District>>("assets/resources/districts.json");
    }

    getCities(): Observable<Array<City>> {
        return this.http.get<Array<City>>("assets/resources/cities.json");
    }

    public getProvinceById = (id: string): Province => {
        let province: Province;
        this.provinces$.subscribe((data) => {
            province = data.filter(province => province.id === id)[0];
        });
        return province;
    }
    public getProvinceByName = (name: string): Observable<Province> => {
        return this.provinces$.pipe(map(data => data.filter(province => province.name === name)[0]));
    }
    public get provinces$() { return this._provinces$.asObservable(); }
    public set selectProvince(selectedProvinceId: string) {
        this._selectedProvince$.next(selectedProvinceId);
    }

    // filter districts by provinceId
    private filterDistricts$ = (provinceId: string) => {
        this._disticts$.subscribe(districts => {
            this._filteredDistricts$.next(districts.filter(district => district.parentId === provinceId));
        });
    }
    public getDistrictById = (id: string): District => {
        let district: District;
        this.districts$.subscribe((data) => {
            district = data.filter(distict => distict.id === id)[0];
        });
        return district;
    }
    public getDistrictByName = (name: string): Observable<District> => {
        return this.districts$.pipe(map(data => data.filter(distict => distict.name === name)[0]));
    }
    public get districts$() { return this._filteredDistricts$.asObservable(); }
    public set selectDistrict(selectedDistrictId: string) {
        this._selectedCity$.next(selectedDistrictId);
    }

    // filter cities by districtId
    private filterCities$ = (districtId: string) => {
        this._cities$.subscribe(cities => {
            // this._filteredCities$.next(cities.filter(city => city.parentId === districtId));
            this._filteredCities$.next(cities.sort((a, b) => a.name.localeCompare(b.name)));
        });
    }
    public getCityById = (id: string): City => {
        let city: City;
        this.cities$.subscribe((data) => {
            city = data.filter(city => city.id === id)[0];
        });
        return city;
    }
    public getCityByName = (name: string): Observable<City> => {
        return this.cities$.pipe(map(data => data.filter(city => city.name === name)[0]));
    }
    public getCitiesByNameLike = (name: string): Observable<City[]> => {
        return this.cities$.pipe(map(data => data.filter(city => city.name.toLowerCase().indexOf(name.toLowerCase()) === 0)));
    }
    public get cities$() { return this._filteredCities$.asObservable(); }
}

export interface Province {
    id: string;
    name: string;
}

export interface District {
    id: string;
    name: string;
    parentId: string;
    displayName: string;
}

export interface City {
    id: string;
    name: string;
    nameLocal: string;
    parentId: string;
    displayName: string;
}