import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Order, OrderStatusChange, OrderService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.css'],
    providers: [OrderService, DecimalPipe]
})
export class OrderDetailComponent implements OnInit {
    @ViewChild('downloadPdfLink') private downloadPdfLink: ElementRef;
    public order: Order = null;
    public dataLoaded: boolean = false;
    public isRedirectingToPaymentPage: boolean = false;
    public settlePaymentError: string = null;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private orderService: OrderService) {
        const orderNumber = this.activatedRoute.snapshot.queryParams['orderNumber'];
        if (orderNumber === undefined || orderNumber === null)
            this.router.navigate(['home']);
        else {
            this.orderService.getDetails(orderNumber).subscribe((result) => {
                this.order = result['result'];
                //console.log(this.order.statusTimeline);
                this.dataLoaded = true;
                //console.log(this.order);
            }, (error) => {
                //console.log(error);
                console.log(error.status);  // status code
                console.log(error.error); // error response from API call
            });
        }
    }

    ngOnInit(): void {

    }

    trackOnUpsSite(airwayBillNumber: string): void {
        window.open('https://www.ups.com/track?loc=en_LK&tracknum=' + airwayBillNumber + '&requester=WT/trackdetails', "_blank");
    }

    status(code: string): OrderStatusChange {
        return this.order.statusTimeline.filter(d => d.status === code)[0];
    }

    getOrderStatus(statusCode: string): string {
        const statusTimelinePoints = ['NEW_ORDER', 'PKG_RECEIVED', 'PKG_SHIPPED', 'PKG_ARRIVED', 'DELIVERED'];
        //if (this.status('DELIVERED').isCompleted)
        //    return 'complete';
        let status = this.status(statusCode);
        if (status.isCompleted) return 'complete';

        let index = statusTimelinePoints.findIndex(s => s === statusCode); // get current status order number on timeline
        let topLevelUpdated: boolean = false; // check whether top level status is completed before this, if so marked this also as completed
        statusTimelinePoints.forEach((d, i) => {
            if (i <= index)
                return;

            if (!topLevelUpdated && this.status(d).isCompleted) {
                topLevelUpdated = true;
                return;
            }
        });
        //console.log(topLevelUpdated, 'i was here on level check');
        if (topLevelUpdated) return 'complete';

        if (index === 0) return 'pending';

        // check whether previous is marked completed. if so mark this status as next timeline point (in pending state)
        const previousStatus = this.status(statusTimelinePoints[index - 1]);
        if (previousStatus.isCompleted) return 'pending';

        return '';
    }

    settlePayment(orderNumber: string) {
        this.settlePaymentError = null;
        this.isRedirectingToPaymentPage = true;
        this.orderService.settlePayment(orderNumber).subscribe((result) => {
            //console.log(result);
            window.location.href = result['result']['paymentPageUrl'];
        }, (error) => {
            this.isRedirectingToPaymentPage = false;
                this.settlePaymentError = error.error.errorMessage;
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
        });
    }

    public loadingInvoiceType1: boolean = false;
    public loadingInvoiceType2: boolean = false;
    async viewInvoice(invoiceLink: string, invoiceType: string): Promise<void> {
        this.loadingInvoiceType1 = invoiceType === 'Type1';
        this.loadingInvoiceType2 = invoiceType === 'Type2';

        const blob = await this.orderService.downloadInvoice(invoiceLink);
        const url = window.URL.createObjectURL(blob);

        const link = this.downloadPdfLink.nativeElement;
        link.href = url;
        //link.target = "_blank";
        link.download = this.loadingInvoiceType1 ? `duty-and-freight-invoice_${this.order.orderNumber}.pdf` : `merchandise-invoice_${this.order.orderNumber}.pdf`;
        link.click();
        this.loadingInvoiceType1 = false;
        this.loadingInvoiceType2 = false;
    }
}
