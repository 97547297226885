import { Injectable, PipeTransform } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject, Subject, Observable, of } from "rxjs";
import { Order, SortColumn, SortDirection } from ".";
import { tap, debounceTime, switchMap, delay } from "rxjs/operators";
import { DecimalPipe } from "@angular/common";

interface SearchResult {
    orders: Order[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: SortColumn;
    sortDirection: SortDirection;
}

const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(countries: Order[], column: SortColumn, direction: string): Order[] {
    if (direction === '' || column === '') {
        return countries;
    } else {
        return [...countries].sort((a, b) => {
            const res = compare(`${a[column]}`, `${b[column]}`);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(country: Order, term: string, pipe: PipeTransform) {
    return country.orderNumber.toLowerCase().includes(term.toLowerCase())
        || pipe.transform(country.status.toLowerCase()).includes(term.toLowerCase())
        || pipe.transform(country.origin.toLowerCase()).includes(term.toLowerCase());
}

@Injectable({ providedIn: 'root' })
export class OrderService {
    public static readonly BASE_URL = "eparcel/web/api-gateway/order";

    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _orders$ = new BehaviorSubject<Order[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    private headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    private _state: State = {
        page: 1,
        pageSize: 5,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };

    private _orders: Order[];

    constructor(private http: HttpClient, private pipe: DecimalPipe) {
    }

    public refreshData(): void {
        this.getSummary().subscribe((result) => {
            this._orders = result['result'];
            //console.log(this._orders);

            this._search$.pipe(
                tap(() => this._loading$.next(true)),
                debounceTime(200),
                switchMap(() => this._search()),
                delay(200),
                tap(() => this._loading$.next(false))
            ).subscribe(result => {
                this._orders$.next(result.orders);
                this._total$.next(result.total);
            });

            this._search$.next();
            //console.log(result);
        }, (error) => {
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
        });
    }

    get orders$() { return this._orders$.asObservable(); }
    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }

    set page(page: number) { this._set({ page }); }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

        // 1. sort
        let orders = sort(this._orders, sortColumn, sortDirection);

        // 2. filter
        orders = orders.filter(country => matches(country, searchTerm, this.pipe));
        const total = orders.length;

        // 3. paginate
        orders = orders.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ orders, total });
    }

    placeOrder(order) {
        return this.http.post(`${environment.apiUrl}/${OrderService.BASE_URL}/placeorder`, order, {
            'headers': this.headers
        });
    }

    getSummary() {
        return this.http.get(`${environment.apiUrl}/${OrderService.BASE_URL}/summary`, {
            'headers': this.headers
        });
    }

    getDetails(orderNumber: string) {
        return this.http.get(`${environment.apiUrl}/${OrderService.BASE_URL}/details/` + orderNumber, {
            'headers': this.headers
        });
    }

    settlePayment(orderNumber: string) {
        return this.http.post(`${environment.apiUrl}/${OrderService.BASE_URL}/invoice/settlepayment/` + orderNumber, null, {
            'headers': this.headers
        });
    }

    settlePaymentComplete(token: string) {
        return this.http.post(`${environment.apiUrl}/${OrderService.BASE_URL}/invoice/settlepayment/complete?encryptedToken=${token}`, null, {
            'headers': this.headers
        });
    }

    cancelOrder(orderNumber: string) {
        return this.http.post(`${environment.apiUrl}/${OrderService.BASE_URL}/cancel/${orderNumber}`, null, {
            'headers': this.headers
        });
    }

    async downloadInvoice(invoiceLink: string) {
        // console.log(`${environment.apiUrl}/${OrderService.BASE_URL}/${invoiceLink}`);
        const file = await this.http.get<Blob>(
            `${environment.apiUrl}/${OrderService.BASE_URL}/${invoiceLink}`,
            {
                'headers': this.headers,
                responseType: 'blob' as 'json'
            }).toPromise();
        return file;
    }
}