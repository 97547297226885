import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UpgradeMembershipComponent } from './upgrade-membership/upgrade-membership.component';
import { CompletePaymentComponent } from './complete-payment/complete-payment.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { OrderCancelConfirmationComponent } from './order-cancel-confirmation/order-cancel-confirmation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FileUploadComponent } from './files/fileupload.component';

@NgModule({
    declarations: [
        AppComponent,
        SignupComponent,
        NavbarComponent,
        FooterComponent,
        LoginComponent,
        OrderDetailComponent,
        UpgradeMembershipComponent,
        CompletePaymentComponent,
        VerifyEmailComponent,
        OrderCancelConfirmationComponent,
        EditProfileComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent,
        FileUploadComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppRoutingModule,
        HomeModule,
        HttpClientModule,
        PdfViewerModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
