import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../_services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
    loading: boolean = true;
    errorMessage: string;
    successMessage: string;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private profileService: ProfileService) {
        const token = this.activatedRoute.snapshot.queryParams['token'];
        console.log(token);
        console.log((token === undefined || token === null));
        if (token === undefined || token === null) {
            //this.router.navigate(['home']);
        }
        else {
            console.log("im here");
            this.profileService.signUpComplete(token).subscribe((result) => {
                this.loading = false;
                this.successMessage = result['result'];
                console.log(result['result']);
            }, (error) => {
                this.loading = false;
                this.errorMessage = error.error['errorMessage'];
                console.error(error.status, error.error);   // error response code and message from API call
            });
        }
    }

    ngOnInit(): void { }
}
