import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../_services/profile.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-upgrade-membership',
    templateUrl: './upgrade-membership.component.html',
    styleUrls: ['./upgrade-membership.component.css']
})
export class UpgradeMembershipComponent implements OnInit {
    public isRedirectingToPaymentPage: boolean = false;

    constructor(private profileService: ProfileService) { }

    ngOnInit(): void {
    }

    upgradeMembership(): void {
        this.isRedirectingToPaymentPage = true;
        this.profileService.upgradeMembership().subscribe((result) => {
            //console.log(result);
            window.location.href = result['result']['paymentPageUrl'];
        }, (error) => {
            this.isRedirectingToPaymentPage = false;
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
        });
    }
}
