import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '../_services';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    focus: boolean;
    submitted: boolean = false; // form submission state (to prevent duplicate form submissions)
    resetError: string; // signin response error message (if any)
    resetSuccess: string;

    resetForm = new FormGroup({
        userName: new FormControl('', [Validators.required, Validators.maxLength(200)])
    });

    constructor(private router: Router, private profileService: ProfileService) { }

    ngOnInit(): void { }

    // on reset form submit
    onSubmit(): void {
        if (this.resetForm.invalid || this.submitted)
            return;

        this.submitted = true;
        let form = this.resetForm.value;

        this.profileService.forgotPassword(form.userName).subscribe((result) => {
            //console.log(result);
            this.resetError = ''; // clear error message 
            this.resetSuccess = result['result'];
            //this.submitted = false; // clear submitted state
        }, (error) => {
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
            this.submitted = false; // clear submitted state
            this.resetError = error.error.errorMessage;
        });
    }

    get f() {
        return this.resetForm.controls;
    }

    goRegisterPage(): void {
        this.router.navigate(['register']);
    }

    goLoginPage(): void {
        this.router.navigate(['login']);
    }
}
