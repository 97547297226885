import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { Tokens } from '../_models/token';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private static readonly BASE_URL = "eparcel/web/api-gateway/customer";
    private readonly JWT_TOKEN = 'JWT_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    private loggedUser: string;

    private headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    private currentUserSubject: BehaviorSubject<User>;
    public get currentUser(): Observable<User> {
        return this.currentUserSubject.asObservable();
    }

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    // get token expiration date
    public getTokenExpirationDate(): Date {
        var user = localStorage.getItem("currentUser");
        if (user === undefined || user === null) return null;
        user = JSON.parse(user);

        const date = new Date(user[".expires"]);
        //date.setUTCSeconds(this.currentUserValue.expires_in);
        return date;
    }

    // whether token expired or not (max retry count=2)
    public isTokenExpired(): boolean {
        const date = this.getTokenExpirationDate();
        if (date === undefined || date === null) return true;

        const isExpired: boolean = !(date.valueOf() > new Date().valueOf());
        return isExpired;
    }

    signup(user) {
        return this.http.post(`${environment.apiUrl}/${AuthenticationService.BASE_URL}/signup`, user, {
            'headers': this.headers
        });
    }

    signin(user) {
        return this.http.post<any>(`${environment.apiUrl}/eparcel/web/api-gateway/identity/authenticate`, user, {
            'headers': this.headers
        })
            .pipe(
                tap(tokens => this.doLoginUser(user.username, tokens.result), (error: HttpErrorResponse) => { console.log(error.error); }),
                map(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    return user;
                }));
    }

    logout() {
        this.doLogoutUser();

        // remove user from local storage & log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);

        this.router.navigate(['/login']);
    }

    // whether user is logged in or not
    isLoggedIn() {
        return !!this.getJwtToken();
    }

    // generate new access token using refresh token
    refreshToken() {
        console.log("token refreshing...");
        const body = {
            AccessToken: this.getJwtToken(),
            RefreshToken: this.getRefreshToken()
        };

        return this.http.post<any>(`${environment.apiUrl}/eparcel/web/api-gateway/identity/refreshtoken`, body, {
            'headers': this.headers
        }).pipe(
            tap(tokens => this.doLoginUser(this.loggedUser, tokens), (error: HttpErrorResponse) => { console.log(error.error); }),
            map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    // login user
    private doLoginUser(username: string, tokens: Tokens) {
        //console.log(tokens);
        this.loggedUser = username;
        this.storeTokens(tokens);
    }

    // logout user
    private doLogoutUser() {
        this.loggedUser = null;
        this.removeTokens();
    }

    // get stored access token
    getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    // get stored refresh token
    private getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    // store access token and refresh token
    private storeTokens(tokens: Tokens) {
        localStorage.setItem(this.JWT_TOKEN, tokens.accessToken.token);
        localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }

    // remove stored tokens
    private removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }
}