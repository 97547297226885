import { Component, OnInit } from '@angular/core';
import { FormArray, Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { OrderService, NotificationService } from '../../_services';

@Component({
    selector: 'tab-placeorder',
    templateUrl: './tab-placeorder.component.html',
    styleUrls: ['./tab-placeorder.component.css']
})
export class TabPlaceorderComponent implements OnInit {
    focus: boolean[] = [false, false, false, false, false]; // capture form inputs focus (5 inputs available)
    submitted: boolean = false;

    Origins = [
        { Code: 'AE', Name: 'United Arab Emirates' },
        { Code: 'AU', Name: 'Australia' },
        { Code: 'CN', Name: 'China' },
        { Code: 'IN', Name: 'India' },
        { Code: 'SG', Name: 'Singapore' },
        { Code: 'UK', Name: 'United Kingdom' },
        { Code: 'US', Name: 'United State of America' },
        { Code: 'CA', Name: 'Canada' },
        { Code: 'MY', Name: 'Malaysia' },
        { Code: 'SG', Name: 'Singapore' },
        { Code: 'JP', Name: 'Japan' },

        
    ];
    Currencies = ['AED', 'AUD', 'CNY', 'GBP', 'INR', 'LKR', 'SGD', 'USD','CAD','MYR','PKR','JPY','SGD'];

    placeOrderForm: FormGroup = new FormGroup({
        origin: new FormControl('US', [Validators.required]),
        consolidated: new FormControl(false, [Validators.required]),
        items: this.formBuilder.array([this.createItem()])
    });
    orderItems: FormArray;

    // To be able to check the status of each form-field, defining convenience getter to access each control.
    get f() {
        return this.placeOrderForm.controls;
    }

    get formItems() { return <FormArray>this.placeOrderForm.get('items'); }

    // commodity description validation error message
    descriptionHasError(item): string {
        let f = item.controls;
        if (f.description.errors.minlength)
            return "Description must contain at least 5 characters.";
        if (f.description.errors.maxLength)
            return "Description must not exceed more than 200 characters.";

        return "";
    }

    constructor(
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private notifyService: NotificationService
    ) {
        //this.heading = "Hello " + "Dhanuka Jayasinghe !";
        //this.placeOrderForm = new FormGroup({
        //    origin: new FormControl('US', [Validators.required]),
        //    consolidated: new FormControl(false, [Validators.required]),
        //    items: this.formBuilder.array([this.createItem()])
        //});
    }

    ngOnInit() {
        
    }


    onSubmit(): void {
        // stop here if form is invalid
        if (this.placeOrderForm.invalid || this.submitted)
            return;

        this.submitted = true;

        let form = this.placeOrderForm.value;
        //console.log(form);

        if (Boolean(form['consolidated'])) { // consolidated shipment, place as one order
            this.orderService.placeOrder(form).subscribe((result) => {
                //console.log(result);
                this.onReset(); // trigger onreset event
                let htmlMessage = 'Your order <strong><a href="/order-details?orderNumber=' + result['result']['orderNumber'] + '"></a></strong> has been placed';
                this.notifyService.notify = { code: 200, text: htmlMessage };
                //this.showAlertOk('', htmlMessage, 10000);
                this.orderService.refreshData();
            }, (error) => {
                //console.log(error);
                console.log(error.status);  // status code
                console.log(error.error); // error response from API call
                this.submitted = false; // clear submitted state
                this.notifyService.notify = { code: error.status, text: error.error };
            });
        } else { // send items one by one as separate orders
            let success = false;
            var orderPromise = new Promise<void>((resolve, reject) => {
                Array.from(form['items']).forEach((item) => {
                    let newOrder = form; // copy original form data
                    newOrder['items'] = [item]; // replace items property with current iteration item as an array

                    this.orderService.placeOrder(newOrder).subscribe((result) => {
                        success = true;
                        let htmlMessage = 'Your order <strong><a href="/order-details?orderNumber=' + result['result']['orderNumber'] + '"></a></strong> has been placed';
                        this.notifyService.notify = { code: 200, text: htmlMessage };
                        //this.showAlertOk('', htmlMessage, 10000);
                        this.orderService.refreshData();
                        resolve();
                    }, (error) => {
                        //console.log(error);
                        console.log(error.status);  // status code
                        console.log(error.error); // error response from API call
                            this.notifyService.notify = { code: error.status, text: error.error.errorMessage };
                        //this.showAlertOk('StatusCode: ' + error.status, error.error);
                        resolve();
                    });
                });
            });
            orderPromise.then(() => {
                this.submitted = false; // clear submitted state
                if (success) {
                    this.onReset(); // trigger onreset event
                }
            });

        }
    }

    onReset(): void {
        this.submitted = false; // clear submitted state
        this.placeOrderForm = new FormGroup({ // set form inputs to it's default values
            origin: new FormControl('US', [Validators.required]),
            consolidated: new FormControl(false, [Validators.required]),
            items: this.formBuilder.array([this.createItem()])
        });
    }

    createItem(): FormGroup {
        return this.formBuilder.group({
            description: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(250)]),
            quantity: new FormControl('', [Validators.required, Validators.min(1), Validators.max(99999)]),
            currency: new FormControl('USD', [Validators.required]),
            cost: new FormControl('', [Validators.required, Validators.min(0)]),
            weightUnit: new FormControl('KG', [Validators.required]),
            weight: new FormControl('', [Validators.required, Validators.min(0)]),
            siteOrdered: new FormControl('', [Validators.maxLength(250)])
        });
    }

    addItem(): void {
        this.orderItems = this.placeOrderForm.get('items') as FormArray;
        this.orderItems.push(this.createItem());
    }

    removeItem(index: number): void {
        this.orderItems = this.placeOrderForm.get('items') as FormArray;
        if (this.orderItems.length > 1) // let items keep one item as default, rest are deletable
            this.orderItems.removeAt(index);
    }
}
