import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from '../_common/custom-validators';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { City, DataSourceService } from '../_services';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    focus: boolean[] = [false, false, false, false, false]; // 4 slots to capture focus of max 4 input elements on one form section
    submitted: boolean = false; // form submission state (to prevent duplicate form submissions)
    signupError: string; // signup response error message (if any)
    activePageNumber = 1;

    //Surveys = [
    //    {
    //        Id: 1, Question: "How did you hear about us ?",
    //        Choices: [
    //            { No: 1, Value: "Advertistment" },
    //            { No: 2, Value: "Event" },
    //            { No: 3, Value: "Friend" },
    //            { No: 4, Value: "Facebook" },
    //            { No: 5, Value: "Instagram" },
    //            { No: 6, Value: "Search Engine" },
    //            { No: 7, Value: "UPS Sales" },
    //            { No: 8, Value: "Paddy Hoole" },
    //            { No: 9, Value: "Other" },
    //        ]
    //    }];

    signupForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        phoneNumber: new FormControl('', [Validators.required, Validators.pattern("[0-9]{9}")]),
        phoneNumberCountryCode: new FormControl('+94', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
        accountType: new FormControl('', [Validators.required]),
        // province: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        // district: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        addressLine: new FormControl('', [Validators.required, Validators.maxLength(250)]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(200)]),
        password: new FormControl('', [Validators.required, Validators.minLength(8), // at least 8 characters in length
        CustomValidators.patternValidator(/\d/, { hasNumber: true }), // contains number
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }), // contains uppercase character
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }), // contains lowercase character
        CustomValidators.patternValidator(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, { hasSpecialChars: true }), // contains special character
        ]),
        surveyAnswer: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(200)])
    });

    constructor(private router: Router, private authService: AuthenticationService, public datasource: DataSourceService) {
    }

    ngOnInit() {
        this.filteredOptions = this.signupForm.controls.city.valueChanges.pipe(
            startWith(''),
            map(value => {
                let cities: City[];
                this.datasource.getCitiesByNameLike(value).subscribe(d => {
                    cities = d;
                });
                return cities;
            })
        );
    }
    filteredOptions: Observable<City[]>;

    // To be able to check the status of each form-field, defining convenience getter to access each control.
    get f() {
        return this.signupForm.controls;
    }

    // password validation error message
    get passwordHasError(): string {
        if (this.f.password.errors?.hasNumber)
            return "Password must contain at least one number.";
        if (this.f.password.errors?.hasCapitalCase)
            return "Password must contain at least one uppercase character.";
        if (this.f.password.errors?.hasSmallCase)
            return "Password must contain at least one lowercase character.";
        if (this.f.password.errors?.hasSpecialChars)
            return "Password must contain at least one special character.";
        if (this.f.password.errors?.minlength)
            return "Password must contain at least 8 characters.";
        return "";
    }
    // signup section navigation
    goToNextPage(pageNumber: number): void {
        //console.log(pageNumber);
        this.activePageNumber = pageNumber;
    }

    // on signup form submit
    onSubmit(): void {
        // stop here if form is invalid or already submitted (waiting for submission response)
        if (this.signupForm.invalid || this.submitted)
            return;

        this.submitted = true;

        let form = JSON.parse(JSON.stringify(this.signupForm.value)); // serialize and deserialize to do a deep copy
        form['phoneNumber'] = form['phoneNumberCountryCode'] + form['phoneNumber']; // concat country code to phone number
        //form['surveyChoice'] = Number(form['surveyChoice']); // parse string to number
        // form['province'] = this.datasource.getProvinceById(form['province']).name;
        // form['district'] = this.datasource.getDistrictById(form['district']).name;
        //form['city'] = this.datasource.getCityById(form['city']).name;
        //console.log(form);
        this.authService.signup(form).subscribe((result) => {
            //console.log(result);
            this.signupError = ""; // clear error message 
            this.submitted = false; // clear submitted state
            this.goToNextPage(5); // if success show verify email sent message
        }, (error) => {
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
            this.submitted = false; // clear submitted state
            this.signupError = error.error.errorMessage;
        });
    }

    goLoginPage(): void {
        this.router.navigate(['login']);
    }

    // provinceChange($event) {
    //     //console.log($event.value);
    //     this.datasource.selectProvince = $event.value;
    //     this.datasource.selectDistrict = null;
    //     this.signupForm.patchValue({
    //         'district': '',
    //         'city': ''
    //     });
    // }

    // districtChange($event) {
    //     this.datasource.selectDistrict = $event.value;
    //     this.signupForm.patchValue({ 'city': '' });
    // }
}
