import { ValidationErrors, AbstractControl, ValidatorFn, FormGroup } from "@angular/forms";

export class CustomValidators {
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                // if control is empty return no error
                return null;
            }

            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);

            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };
    }

    static matchValues(matchTo: string, error: ValidationErrors): ValidatorFn {  // name of the control to match to
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value || !control.parent.controls[matchTo].value) {
                // if control is empty return no error
                return null;
            }

            const valid = control.value === control.parent.controls[matchTo].value;

            return valid ? null : error;
        };
    }
}
//export function MustMatch(controlName: string, matchingControlName: string) {
//    return (formGroup: FormGroup) => {
//        const control = formGroup.controls[controlName];
//        const matchingControl = formGroup.controls[matchingControlName];

//        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
//            // return if another validator has already found an error on the matchingControl
//            return;
//        }

//        // set error on matchingControl if validation fails
//        if (control.value !== matchingControl.value) {
//            matchingControl.setErrors({ mustMatch: true });
//        } else {
//            matchingControl.setErrors(null);
//        }
//    }
//}
