import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private _notification$ = new BehaviorSubject<Notification>(null);

    set notify(notification: Notification) {
        this._notification$.next(notification);
    }

    get notification(): Observable<Notification> {
        return this._notification$.asObservable();
    }
}

export interface Notification {
    code: number;
    text: string;
}