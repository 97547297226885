import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { UpgradeMembershipComponent } from './upgrade-membership/upgrade-membership.component';
import { CompletePaymentComponent } from './complete-payment/complete-payment.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { OrderCancelConfirmationComponent } from './order-cancel-confirmation/order-cancel-confirmation.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FileUploadComponent } from './files/fileupload.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'order-details', component: OrderDetailComponent, canActivate: [AuthGuard] },
    { path: 'order/cancel/confirmation', component: OrderCancelConfirmationComponent, canActivate: [AuthGuard] },
    { path: 'user-profile/membership/upgrade', component: UpgradeMembershipComponent, canActivate: [AuthGuard] },
    { path: 'user-profile/edit', component: EditProfileComponent, canActivate: [AuthGuard] },
    { path: 'payment/complete', component: CompletePaymentComponent }, // accessible to outside, for the payment gateway return URL
    { path: 'register', component: SignupComponent }, // allow anonymous
    { path: 'register/verify-email', component: VerifyEmailComponent }, // allow anonymous
    { path: 'account/forgot-password', component: ForgotPasswordComponent }, // allow anonymous
    { path: 'account/change-password', component: ChangePasswordComponent }, // allow anonymous
    { path: 'login', component: LoginComponent }, // allow anonymous
    { path: 'upfile', component: FileUploadComponent }, // allow anonymous
    { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard] }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: false, // true -> use hash strategy, false -> use location strategy
        })
    ],
    exports: []
})
export class AppRoutingModule { }
