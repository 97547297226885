import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Order, NgbdSortableHeader, OrderService, SortEvent } from '../../_services';
import { Observable } from 'rxjs';

@Component({
    selector: 'tab-myorders',
    templateUrl: './tab-myorders.component.html',
    styleUrls: ['./tab-myorders.component.css']
})
export class TabMyordersComponent implements OnInit {
    orders$: Observable<Order[]>;
    total$: Observable<number>;

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    constructor(public orderService: OrderService) {
        this.orderService.refreshData();
        this.orders$ = orderService.orders$;
        this.total$ = orderService.total$;
    }

    ngOnInit(): void {
    }


    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.orderService.sortColumn = column;
        this.orderService.sortDirection = direction;
    }

    getStatusText(code: string): string {
        switch (code) {
            case 'NEW_ORDER':
                return 'Order accepted';
            case 'PKG_RECEIVED':
                return 'Package received at Origin depot';
            case 'PKG_SHIPPED':
                return 'Package shipped';
            case 'PKG_ARRIVED':
                return 'Package arrived to Sri Lanka';
            case 'DELIVERED':
                return 'Package delivered';
            case 'CANCELLED':
                return 'Cancelled';
            default:
                return 'Unknown';
        }
    }

    getActionButtonText(order: Order): string {
        if (order.invoicePayment !== null && order.invoicePayment !== undefined && order.invoicePayment.status === 'PENDING')
            return 'Pay Now';

        if (order.status !== 'NEW_ORDER' && order.status !== 'CANCELED')
            return 'Track Order';

        return 'View Order';
    }

    trackOnUpsSite(airwayBillNumber: string): void {
        window.open('https://www.ups.com/track?loc=en_LK&tracknum=' + airwayBillNumber + '&requester=WT/trackdetails', "_blank");
    }
}
