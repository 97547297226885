import { Component, Input, PipeTransform, Pipe } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { OrderService, NotificationService } from '../_services';
import { IAlert } from '../_common/alert.interface';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [OrderService, DecimalPipe]
})

export class HomeComponent {
    @Input()
    public alerts: Array<IAlert> = [];
    heading: string;

    constructor(private notifyService: NotificationService) {
        this.notifyService.notification.subscribe(notification => {
            if (notification !== null) {
                if (notification.code === 200)
                    this.showAlertOk('', notification.text, 10000);
                else
                    this.showAlertError('StatusCode: ' + notification.code, notification.text);
            }
        });
    }

    // close alert
    closeAlert(alert: IAlert) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    // show alert (OK response), timeout=5000 (default 5sec)
    showAlertOk(title: string, message: string, timeout: number = 5000) {
        let alert: IAlert = {
            id: (this.alerts.length == 0 ? 1 : this.alerts[this.alerts.length - 1].id++),
            type: 'success',
            strong: title,
            message: message,
            icon: 'ni ni-like-2'
        };
        this.alerts.push(alert);
        window.setTimeout(() => { this.closeAlert(alert); }, timeout); // use arrow function to fix this. context issue inside callback function
    }

    // show alert (ERROR response), timeout=5000 (default 5sec)
    showAlertError(title: string, message: string, timeout: number = 5000) {
        let alert: IAlert = {
            id: (this.alerts.length == 0 ? 1 : this.alerts[this.alerts.length - 1].id++), // last alert id + 1
            type: 'danger',
            strong: title,
            message: message,
            icon: 'ni ni-support-16'
        };
        this.alerts.push(alert);
        window.setTimeout(() => { this.closeAlert(alert); }, timeout)
    }
}


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    public transform(value: any, type: string): any {
        const sanitizedContent = DOMPurify.sanitize(value);
        return this.sanitized.bypassSecurityTrustHtml(sanitizedContent);

    }
}
