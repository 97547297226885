import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { CustomValidators } from '../_common/custom-validators';
import { ProfileService } from '../_services';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    focus: boolean[] = [false, false];
    submitted: boolean = false;
    passwordChangeError: string;
    passwordChangeSuccess: string;

    resetForm: FormGroup;
    token: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private profileService: ProfileService) { }

    ngOnInit(): void {
        this.token = this.activatedRoute.snapshot.queryParams['token'];
        if (this.token === undefined || this.token === null) {
            this.router.navigate(['login']);
        }

        this.resetForm = new FormGroup({
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(6),
                CustomValidators.patternValidator(/\d/, { hasNumber: true }), // contains number
                CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }), // contains uppercase character
                CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }), // contains lowercase character
                CustomValidators.patternValidator(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, { hasSpecialChars: true }), // contains special character
            ]),
            confirmPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(6),
                CustomValidators.matchValues('password', { mustMatch: true })])
        });
    }

    get f() {
        return this.resetForm.controls;
    }

    // password validation error message
    get passwordHasError(): string {
        if (this.f.password.errors.minlength)
            return "Password must contain at least 8 characters.";
        if (this.f.password.errors.hasNumber)
            return "Password must contain at least one number.";
        if (this.f.password.errors.hasCapitalCase)
            return "Password must contain at least one uppercase character.";
        if (this.f.password.errors.hasSmallCase)
            return "Password must contain at least one lowercase character.";
        if (this.f.password.errors.hasSpecialChars)
            return "Password must contain at least one special character.";
        return "";
    }


    onSubmit(): void {
        if (this.resetForm.invalid || this.submitted)
            return;

        this.submitted = true;

        let form = this.resetForm.value;
        //console.log(form);

        this.profileService.changePassword(this.token, form.password).subscribe((result) => {
            //console.log(result);
            this.passwordChangeError = ''; // clear error message 
            this.passwordChangeSuccess = result['result'];
            this.router.navigate(['login']); // redirect to sign-in page
            //this.submitted = false; // clear submitted state
        }, (error) => {
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
            this.submitted = false; // clear submitted state
            this.passwordChangeError = error.error.errorMessage;
        });
    }
}
