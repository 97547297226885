import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: 'root' })
export class ProfileService {
    public static readonly BASE_URL = "eparcel/web/api-gateway/customer";

    private headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private http: HttpClient) {

    }

    getProfile() {
        return this.http.get(`${environment.apiUrl}/${ProfileService.BASE_URL}/profile`, {
            'headers': this.headers
        });
    }

    editProfile(data: any) {
        return this.http.post(`${environment.apiUrl}/${ProfileService.BASE_URL}/profile/edit`, data, {
            'headers': this.headers
        });
    }

    upgradeMembership() {
        return this.http.post(`${environment.apiUrl}/${ProfileService.BASE_URL}/membership/upgrade`, null, {
            'headers': this.headers
        });
    }

    upgradeMembershipComplete(token: string) {
        return this.http.post(`${environment.apiUrl}/${ProfileService.BASE_URL}/membership/upgrade/complete?encryptedToken=${token}`, null, {
            'headers': this.headers
        });
    }

    signUpComplete(token: string) {
        return this.http.post(`${environment.apiUrl}/${ProfileService.BASE_URL}/signup/complete?encryptedToken=${token}`, null, {
            'headers': this.headers
        });
    }

    forgotPassword(username: string) {
        console.log(username);
        return this.http.post(`${environment.apiUrl}/${ProfileService.BASE_URL}/profile/resetpassword?username=${username}`, null, {
            'headers': this.headers
        });
    }

    changePassword(token: string, newPassword: string) {
        return this.http.post(`${environment.apiUrl}/${ProfileService.BASE_URL}/profile/resetpassword/complete`, { encryptedToken: token, newPassword: newPassword }, {
            'headers': this.headers
        });
    }
}