import { Component, OnInit } from '@angular/core';
import { Profile } from '../../_services/profile';
import { ProfileService } from '../../_services';

@Component({
    selector: 'tab-myaccount',
    templateUrl: './tab-myaccount.component.html',
    styleUrls: ['./tab-myaccount.component.css']
})
export class TabMyaccountComponent implements OnInit {
    public profile: Profile;
    public isProfileLoaded: boolean = false;

    constructor(private profileService: ProfileService) {
        this.profileService.getProfile().subscribe((result) => {
            this.profile = result['result'] as Profile;
            //console.log(this.profile);
            this.isProfileLoaded = true;
            //console.log(this.profile);
        }, (error) => {
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
        });
    }

    ngOnInit(): void {
    }

}
