import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Component({
    selector: 'tab-myrates',
    templateUrl: './tab-myrates.component.html',
    styleUrls: ['./tab-myrates.component.css']
})
export class TabMyratesComponent implements OnInit {
    pdfSrc = environment.myRatesSheet;

    constructor( private http: HttpClient,) { }

    ngOnInit(): void {
 
    }

}
