import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    focus: boolean[] = [false, false]; // 2 slots to capture focus 2 input elements
    submitted: boolean = false; // form submission state (to prevent duplicate form submissions)
    signinError: string; // signin response error message (if any)
    returnUrl: string = "";

    signinForm = new FormGroup({
        userName: new FormControl('', [Validators.required, Validators.maxLength(200)]),
        password: new FormControl('', [Validators.required, Validators.maxLength(200)])
    });

    // To be able to check the status of each form-field, defining convenience getter to access each control.
    get f() {
        return this.signinForm.controls;
    }

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthenticationService) {

    }

    ngOnInit() {
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
        console.log(this.returnUrl);
        // redirect to home if already logged in
        if (this.authService.currentUserValue) {
            this.router.navigate(['/home']);
        }
    }

    onSubmit(): void {
        if (this.signinForm.invalid || this.submitted)
            return;

        this.submitted = true;

        let form = this.signinForm.value;
        //console.log(this.signinForm.value);
        this.authService.signin(form).subscribe((result) => {
            //console.log(result);
            this.signinError = ""; // clear error message 
            this.submitted = false; // clear submitted state
            if (this.returnUrl === undefined || this.returnUrl === null)
                this.router.navigate(['home']); // redirect to home page
            else // redirect to return url
                this.router.navigate([this.returnUrl]);
        }, (error) => {
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
            this.submitted = false; // clear submitted state
            this.signinError = error.error.errorMessage;
        });
    }

    goRegisterPage(): void {
        this.router.navigate(['register']);
    }
}
