import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataSourceService, ProfileService } from '../_services';
import { Profile } from '../_services/profile';
import { Router } from '@angular/router';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
    public focus: boolean[] = [false, false, false, false, false, false, false, false, false]; // 4 slots to capture focus of max 4 input elements on one form section
    public submitted: boolean = false; // form submission state (to prevent duplicate form submissions)
    public editError: string; // edit response error message (if any)
    private initialLoad: boolean = true;
    public edited: boolean = false;

    //public profile: Profile;
    private backupProfile: Profile;
    private backupForm: any;
    public isProfileLoaded: boolean = false;

    constructor(
        private router: Router,
        private profileService: ProfileService,
        public datasource: DataSourceService
    ) { }

    ngOnInit(): void {
        this.profileService.getProfile().subscribe((result) => {
            //this.profile = result['result'] as Profile;
            this.backupProfile = result['result'] as Profile;
            this.isProfileLoaded = true;

            //console.log(this.profile);
            this.fillForm(this.backupProfile);
        }, (error) => {
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
        });
    }

    editProfileForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        phoneNumber: new FormControl('', [Validators.required, Validators.pattern("[0-9]{9}")]),
        phoneNumberCountryCode: new FormControl('+94', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
        //accountType: new FormControl('', [Validators.required]),
        province: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        district: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        addressLine: new FormControl('', [Validators.required, Validators.maxLength(250)])
    });

    // To be able to check the status of each form-field, defining convenience getter to access each control.
    get f() {
        return this.editProfileForm.controls;
    }

    provinceChange($event) {
        //console.log($event.value);
        this.datasource.selectProvince = $event.value;
        this.datasource.selectDistrict = null;
        this.editProfileForm.patchValue({
            'district': '',
            'city': ''
        });
    }

    districtChange($event) {
        this.datasource.selectDistrict = $event.value;
        this.editProfileForm.patchValue({ 'city': '' });
    }

    // on signup form submit
    onSubmit(): void {
        // stop here if form is invalid or already submitted (waiting for submission response)
        if (this.editProfileForm.invalid || this.submitted)
            return;

        this.submitted = true;

        let form = this.editProfileForm.value;
        form['phoneNumber'] = form['phoneNumberCountryCode'] + form['phoneNumber']; // concat country code to phone number
        //form['surveyChoice'] = Number(form['surveyChoice']); // parse string to number
        form['province'] = this.datasource.getProvinceById(form['province']).name;
        form['district'] = this.datasource.getDistrictById(form['district']).name;
        form['city'] = this.datasource.getCityById(form['city']).name;

        //console.log(form);
        this.profileService.editProfile(form)
            .subscribe((result) => {
                //console.log(result);
                this.editError = ""; // clear error message 
                this.submitted = false; // clear submitted state
                this.router.navigate(['/home']);
            }, (error) => {
                //console.log(error);
                console.log(error.status);  // status code
                console.log(error.error); // error response from API call
                this.submitted = false; // clear submitted state
                this.editError = error.error.errorMessage;
            });
    }

    // fill form inputs
    fillForm(profile: Profile) {
        this.editProfileForm.patchValue({
            title: profile.title,
            firstName: profile.firstName,
            lastName: profile.lastName,
            phoneNumber: profile.phoneNumber.replace('+94', ''),
            //accountType: profile.accountType,
            addressLine: profile.defaultAddress ? profile.defaultAddress.addressLine : ''
        });

        // set province and trigger onchange event
        if (profile.defaultAddress) {
            this.datasource.getProvinceByName(profile.defaultAddress.province)
                .subscribe(province => {
                    if (province !== undefined && province !== null) {
                        this.editProfileForm.get('province').patchValue(province.id);
                        this.provinceChange({ value: province.id });

                        // set district and trigger onchange event
                        this.datasource.getDistrictByName(profile.defaultAddress.district)
                            .subscribe(district => {
                                if (district !== undefined && district !== null) {
                                    //console.log(district);
                                    this.editProfileForm.get('district').patchValue(district.id);
                                    this.districtChange({ value: district.id });

                                    // set city
                                    this.datasource.getCityByName(profile.defaultAddress.city)
                                        .subscribe(city => {
                                            if (city !== undefined && city !== null) {
                                                this.editProfileForm.get('city').patchValue(city.id);

                                                if (this.initialLoad) {
                                                    this.backupForm = this.editProfileForm.value;
                                                    this.initialLoad = false;
                                                    // start listen for changes
                                                    this.editProfileForm.valueChanges.subscribe(newForm => {
                                                        this.edited = this.hasChanges(this.backupForm, newForm);
                                                    });
                                                }
                                            }
                                        });
                                }
                            });
                    }
                });
        } else {
            if (this.initialLoad) {
                this.backupForm = this.editProfileForm.value;
                this.initialLoad = false;
                // start listen for changes
                this.editProfileForm.valueChanges.subscribe(newForm => {
                    this.edited = this.hasChanges(this.backupForm, newForm);
                });
            }
        }
    }

    resetForm(): void {
        //console.log(this.backupProfile);
        this.fillForm(this.backupProfile);
    }

    // detect new changes on the form
    hasChanges = (oldForm: any, newForm: any): boolean => {
        return oldForm.title != newForm.title
            || oldForm.firstName != newForm.firstName
            || oldForm.lastName != newForm.lastName
            || oldForm.phoneNumber != newForm.phoneNumber
            || oldForm.phoneNumberCountryCode != newForm.phoneNumberCountryCode
            //|| oldForm.accountType != newForm.accountType
            || oldForm.province != newForm.province
            || oldForm.district != newForm.district
            || oldForm.city != newForm.city
            || oldForm.addressLine != newForm.addressLine;
    };
}
