import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../_services/profile.service';
import { PaymentResponse, OrderService } from '../_services';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-complete-payment',
    templateUrl: './complete-payment.component.html',
    styleUrls: ['./complete-payment.component.css'],
    providers: [OrderService, DecimalPipe]
})
export class CompletePaymentComponent {
    @ViewChild('downloadPdfLink') private downloadPdfLink: ElementRef;
    public errorMessage: string;
    public successMessage: string;
    public loading: boolean = true;
    public paymentResponse: PaymentResponse;

    constructor(private activatedRoute: ActivatedRoute, private router: Router,
        private profileService: ProfileService,
        private orderService: OrderService) {
        const token = this.activatedRoute.snapshot.queryParams['token'];
        const requestType = this.activatedRoute.snapshot.queryParams['requestType'];

        // if empty request -> redirect to home page
        if (token === undefined || token === null || requestType == undefined || requestType === null)
            this.router.navigate(['home']);
        else if (requestType === 'membershipUpgrade') {
            this.profileService.upgradeMembershipComplete(token).subscribe((result) => {
                //console.log(result['result']);
                this.errorMessage = '';
                this.successMessage = "Thank you for your payment";
                this.paymentResponse = result['result'] as PaymentResponse;
                this.loading = false;
            }, (error) => {
                //console.log(error);
                console.error(error.status, error.error);  // error response message and code from API call
                this.errorMessage = error.error["errorMessage"];
                this.loading = false;
            });
        } else if (requestType === 'invoicePayment') {
            this.orderService.settlePaymentComplete(token).subscribe((result) => {
                //console.log(result['result']);
                this.errorMessage = '';
                this.successMessage = "Thank you for your payment";
                this.paymentResponse = result['result'] as PaymentResponse;
                console.log(this.paymentResponse);
                this.loading = false;
            }, (error) => {
                //console.log(error);
                console.error(error.status, error.error);  // error response message and code from API call
                this.errorMessage = error.error["errorMessage"];
                this.loading = false;
            });
        }
    }

    public loadingInvoiceType1: boolean = false;
    public async viewInvoice(invoiceLink): Promise<void> {
        this.loadingInvoiceType1 = true;
        const blob = await this.orderService.downloadInvoice(invoiceLink);
        const url = window.URL.createObjectURL(blob);

        const link = this.downloadPdfLink.nativeElement;
        link.href = url;
        //link.target = "_blank";
        link.download =  `invoice.pdf`;
        link.click();
        this.loadingInvoiceType1 = false;
    }
}
