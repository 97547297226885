import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'tab-depotorigins',
    templateUrl: './tab-depotorigins.component.html',
    styleUrls: ['./tab-depotorigins.component.css']
})
export class TabDepotOriginsComponent {
    pdfSrc = environment.deliveryInstructions;
    constructor() { }
}
