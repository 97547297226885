import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../_services';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-order-cancel-confirmation',
    templateUrl: './order-cancel-confirmation.component.html',
    styleUrls: ['./order-cancel-confirmation.component.css'],
    providers: [OrderService, DecimalPipe]
})
export class OrderCancelConfirmationComponent implements OnInit {
    public orderNumber: string;
    public errorMessage: string = "";
    public loading: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private orderService: OrderService) {
        this.orderNumber = this.activatedRoute.snapshot.queryParams['orderNumber'];
        if (this.orderNumber === undefined || this.orderNumber === null)
            this.router.navigate(['home']);
    }

    ngOnInit(): void {
    }

    cancelOrder() {
        this.loading = true;
        this.orderService.cancelOrder(this.orderNumber).subscribe((result) => {
            console.log(result);
            this.loading = false;
            this.router.navigate(['home']);
        }, (error) => {
            this.loading = false;
            this.errorMessage = error.error['errorMessage'];;
            //console.log(error);
            console.log(error.status);  // status code
            console.log(error.error); // error response from API call
        });
    }
}
