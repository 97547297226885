import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../_services';
import { switchMap, filter, take, finalize, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

//HTTP interceptor to add JWT token to every HTTP request as a Bearer token
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    // if destination is API URL => add bearer token (if available on the storage)
    if (this.requestDestination(request.url) == environment.apiUrl && request.headers.get("Content-Type") == "application/json") {
      if (this.authService.getJwtToken()) {
        request = this.addToken(request, this.authService.getJwtToken());
      }

      // if unauthorized (401) => refresh token
      return next.handle(request).pipe(catchError(error => {
        //console.log(error);
        if (error.status === 401) {
          //console.log("I was a 401 error");
          return this.handle401Error(request, next);
        } else if (error.status === 0) { // unknown error -> logout
          //console.log("I was a unknown error");
          this.authService.logout();
        } else {
          return throwError(error);
        }
      }));
    }

    // do process as normal request
    return next.handle(request);
  }

  // get http request destination
  private requestDestination(url): string {
    const a = document.createElement('a');
    a.href = url;
    return `${a.protocol}//${a.host}`; //ex: output => http://localhost:8080
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // for 401 error, refresh tokens
      return this.authService.refreshToken()
        .pipe(
          switchMap(user => {
            console.log(user);
            if (user) {
              this.refreshTokenSubject.next(user.access_token);
              return next.handle(this.addToken(request, user.access_token));
            }

            // if token refresh failed => logout 
            this.authService.logout();
            return next.handle(null);
          }),
          catchError(err => {
            // if token refresh failed (failed due to connection timeout or refresh token expiration) => logout
            <any>this.authService.logout();
            return next.handle(null);
          }),
          finalize(() => {
            this.isRefreshing = false;
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
